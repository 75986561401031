
($ => {
  $.fn.ModalMethods = function (settings) {
    this.settingsDef = {
      $headerChild: '',
      $bodyChild: '',
      $footerChild: '',
      loading: {
        $headerChild: '',
        $bodyChild: ''
      },
      trigger: {
        regist: {
          ajax: {
            url: ''
          }
        }
      },
      error: {
        selector: '#dummyModalErrorMessage'
      },
      form: {
        selector: '#dummyFormInput'
      }
    }
    this.settings = $.extend(this.settingsDef, settings)
    const myModal = '#' + this.attr('id')

    this.modalToggle = function (data) {
      const { regist } = this.settings.trigger

      if (regist.$bodyChild) {
        $(myModal).find('.modal-body').empty().append(regist.$bodyChild)
      } else {
        $(myModal).find('.modal-body').empty().append(data[0].contents)
      }

      if (regist.$footerChild) {
        $(myModal).find('.modal-footer').empty().append(regist.$footerChild)
      } else {
        $(myModal).find('.modal-footer').empty().append($('<a class="btn btn-white" href="' + data[0].href + '">閉じる</a>'))
      }

      $(myModal).find('.modal-content').animate({
        height: $(myModal).find('.modal-header').outerHeight() + $(myModal).find('.modal-body').outerHeight() + $(myModal).find('.modal-footer').outerHeight()
      }, 500, () => {
        $(myModal).find('.modal-body .form-group').first().addClass('border-top')
        $(myModal).find('.modal-loading').hide()
        $(myModal).find('.modal-body, .modal-footer').css({ opacity: '0', visibility: 'hidden' }).addClass('transition-05')
        $(myModal).find('.modal-body, .modal-footer').css({ opacity: '1', visibility: 'visible' })
        $(myModal).find('.modal-header').find('modal-close').css({ opacity: '1', visibility: 'visible' })
        $(myModal).find('.modal-header').empty().append('<div>' + data[0].title + '</div><a class="btn btn-pill btn-white modal-close" type="button" aria-invalid="false" href="' + data[0].href + '"><i class="c-icon cil-x font-weight-bold"></i></a>')
      })
    }

    function isValidDisable(settiongs) {
      const myForm = settiongs.form.selector

      const $confirmBtn = $('[data-trigger="confirm"]').filter('[data-target="' + myModal + '"]')
      // eslint-disable-next-line no-negated-condition
      if (!$(myForm).find('.is-invalid').length) {
        $confirmBtn.prop('disabled', false)
      } else {
        $confirmBtn.prop('disabled', 'disabled')
      }
    }

    this.$myModal = $(this)

    this.modalInit = function () {
    }

    this.modalStart = function (settings) {
      $(myModal).attr('data-status', 'confirm')
      $(myModal).find('.modal-header').append(settings.$headerChild)
      $(myModal).find('.modal-body').append(settings.$bodyChild)
      $(myModal).find('.modal-footer').append(settings.$footerChild)

      $(myModal).on('show.coreui.modal', e => {
        if ($(myModal).attr('data-status') === 'confirm') {
          $(myModal).find('.modal-content').css('height', '')
        }
      })
    }

    this.buttonsTrigger = function (settings) {
      const myForm = settings.form.selector
      const errorMessage = settings.error.selector
      const ajaxUrl = settings.trigger.regist.ajax.url
      const options = settings
      $(myModal).find('[data-trigger]').on('click', () => {
        const triggerStatus = $(event.target).attr('data-trigger')
        const { loading } = this.settings
        const { trigger } = this.settings

        if (triggerStatus === 'regist') {
          $(errorMessage).empty()

          // $(myModal).find('.modal-content').height($(myModal).find('.modal-content').height())
          const $modalLoading = $('<div class="modal-loading" />')
          $modalLoading.append('<div class="modal-loading-inner" />')
          $modalLoading.find('.modal-loading-inner')
            .append('<div class="modal-loading-text" />')
            .append(
              '<div class="sk-fading-circle" style="margin-top: 0;">\n' +
              ' <div class="sk-circle1 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle2 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle3 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle4 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle5 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle6 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle7 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle8 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle9 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle10 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle11 sk-circle m-auto"></div>\n' +
              ' <div class="sk-circle12 sk-circle m-auto"></div>\n' +
              '</div>'
            )

          $(myModal).find('.modal-body, .modal-footer ').css({ opacity: '0', visibility: 'visible' })
          $(myModal).find('.modal-header').after($modalLoading)
          const $formInputData = myForm ? $(myForm).serializeArray() : ''
          if (loading.$headerChild) {
            $(myModal).find('.modal-header').empty().append(loading.$headerChild.get(0))
          }

          if (loading.$bodyChild) {
            $(myModal).find('.modal-loading').find('.modal-loading-text').empty().append(loading.$bodyChild.get(0))
          }

          $(myModal).find('.modal-content').height($(myModal).find('.modal-header').outerHeight() + $(myModal).find('.modal-body').outerHeight() + $(myModal).find('.modal-footer').outerHeight())

          $.ajax({
            url: ajaxUrl,
            type: 'POST',
            dataType: 'json',
            timeout: 15000,
            cache: false,
            data: $formInputData
          })

            .done((data, textStatus) => {
              // サーバがバリデーションエラーを返した場合
              if (data[0] === false) {
                $(myModal).modal('hide')
                let error_messages = '<ul class="alert-danger text-danger list-unstyled font-weight-bold p-4 mb-4">\n'

                $.each(data[1], (index, value) => {
                  if (value.error_message) {
                    error_messages += '<li><div class="cil-exclamation-circle"></div>' + value.error_message + '</li>'
                  }
                })
                error_messages += '</ul>'
                $(this.settings.error.selector).append(error_messages)

                $.each(data[1], (index, value) => {
                  if (value.error_message) {
                    const $nameInput = $(this.settings.form.selector).find('[name=' + value.name + ']')
                    const $nameInputError = $('<div id="' + value.name + '-error" class="error font-italic text-danger invalid-feedback">' + value.error_message + '</div>')
                    $nameInput.val('')
                    $nameInput.next('#' + value.name + '-error').remove()
                    $nameInput.addClass('is-invalid')
                    $nameInput.after($nameInputError)
                  }
                })
                if (myForm) {
                  isValidDisable(options)
                }
              } else {
              // 成功時処理
                $(myModal).attr('data-status', 'regist')
                this.modalToggle(data)
              }

              // console.log('status:' + textStatus)
              // console.log('data:' + JSON.stringify(data, null, '    '))
            })
            .fail((jqXHR, textStatus, errorThrown) => {
              // console.log('code:' + jqXHR.status)
              // console.log('status:' + textStatus)
              // console.log('errorThrown:' + errorThrown)
            })
        } else {
          $(myModal).attr('data-status', 'confirm')
        }
      })
    }

    this.toggleModal = function () {
      // console.log(1)
    }

    this.modalRun = function () {
      // this.modalInit()
      this.modalStart(this.settings)
      this.buttonsTrigger(this.settings)
    }

    return this
  }
})(jQuery)
